import { VerificationStatus } from '@idalon/api-client';
import { useMutation, useQuery } from '@tanstack/react-query';

import { queryKeys } from 'src/api/queryKeys';

import { adminApi } from './api';

export const useGetSettingsQuery = () =>
  useQuery({
    queryKey: queryKeys.adminSettings(),
    queryFn: () => adminApi.getSettings(),
    select: (data) => data.data,
  });

export const useGetModeratorNightVerificationsQuery = ({
  limit,
  offset,
  status,
  enabled = true,
}: {
  limit?: number;
  offset?: number;
  status?: VerificationStatus;
  enabled?: boolean;
}) =>
  useQuery({
    queryKey: queryKeys.moderatorNightVerifications({ limit, offset, status }),
    queryFn: () => adminApi.getModeratorNightVerifications({ limit, offset, status }),
    select: (data) => data.data,
    keepPreviousData: true,
    enabled,
  });

export const useResetSettingsMutation = () => useMutation({ mutationFn: adminApi.resetSettings });

export const useUpdateSettingsMutation = () => useMutation({ mutationFn: adminApi.updateSettings });

export const useUpdateNightVerificationMutation = () => useMutation({ mutationFn: adminApi.updateNightVerification });

export const useUpdateUserMutation = () => useMutation({ mutationFn: adminApi.updateUser });

export const useMergeUserMutation = () => useMutation({ mutationFn: adminApi.mergeUser });

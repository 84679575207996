import { GetProfileResponse } from '@idalon/api-client';

import { persistentAtom } from 'src/utils/helpers/atoms/persistentAtom';

export const sessionAtom = persistentAtom<{
  isLoggedIn: boolean;
  user?: GetProfileResponse;
}>('session', {
  isLoggedIn: false,
});

import { UserRole, VerificationStatus } from '@idalon/api-client';
import styled from 'styled-components';

import { useGetModeratorNightVerificationsQuery } from 'src/api/admin/query';
import { boxShadow } from 'src/assets/mixins';
import { useSession } from 'src/utils/hooks/useSession';

const Container = styled.span`
  position: absolute;
  top: -2px;
  right: 0;
  padding: 0.5px 3px;
  font-size: 10px;
  color: ${({ theme }) => theme.textColors.base};
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 2px;
  font-weight: bold;
  pointer-events: none;
  ${boxShadow}
`;

export const NotificationsPill = () => {
  const { user } = useSession();

  const { data: { total: pendingRequestsCount } = { total: 0 } } = useGetModeratorNightVerificationsQuery({
    limit: 0,
    status: VerificationStatus.pending,
    enabled: user && (user.role === UserRole.mod || user.role === UserRole.admin),
  });

  if (pendingRequestsCount === 0) {
    return null;
  }

  return <Container>{pendingRequestsCount}</Container>;
};

import Fingerprint from '@fingerprintjs/fingerprintjs';
import { get, set } from 'browser-cookies';
import { addYears } from 'date-fns';

export const getFingerprint = async () => {
  const cookieFingerprint = get('fp');

  if (cookieFingerprint) {
    return cookieFingerprint;
  }

  const generatedFingerprint = await Fingerprint.load()
    .then((fp) => fp.get())
    .then((result) => result.visitorId);

  set('fp', generatedFingerprint, { expires: addYears(new Date(), 1).toUTCString() });

  return generatedFingerprint;
};

import { UserRole } from '@idalon/api-client';
import { useQueryClient } from '@tanstack/react-query';
import { erase } from 'browser-cookies';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { axiosInstance } from 'src/api/axiosInstance';
import { queryKeys } from 'src/api/queryKeys';
import { useDeleteSessionMutation } from 'src/api/sessions/query';
import { messages } from 'src/assets/messages';
import { Dropdown, DropdownItem } from 'src/components/ui/Dropdown';
import { IconButton } from 'src/elements/Icon';
import { useMember } from 'src/utils/hooks/useSession';

import { NotificationsPill } from './NotificationsPill';

export const ProfileDropdown = () => {
  const { formatMessage } = useIntl();
  const { uuid, role, ign } = useMember();
  const { mutateAsync: deleteSession } = useDeleteSessionMutation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await deleteSession({});

      erase('at');
      erase('rt');

      delete axiosInstance.defaults.headers.common['x-access-token'];
      delete axiosInstance.defaults.headers.common['x-refresh-token'];

      navigate('/');

      queryClient.resetQueries(queryKeys.profile());
      queryClient.invalidateQueries(queryKeys.nights());
    } catch {
      //
    }
  };

  const dropdownItems = useMemo(() => {
    const items: DropdownItem[] = [
      {
        label: formatMessage(messages.account),
        type: 'text',
        onClick: () => navigate('/account'),
        icon: 'settings',
      },
    ];

    if (role === UserRole.mod || role === UserRole.admin) {
      items.push({
        label: formatMessage({ id: 'nav.moderation', defaultMessage: 'Moderation' }),
        type: 'text',
        onClick: () => navigate(`/moderation`),
        icon: 'shield_person',
      });
    }

    if (ign) {
      items.push({
        label: formatMessage(messages.profile),
        type: 'text',
        onClick: () => navigate(`/users/${ign}`),
        icon: 'person',
      });
    }

    items.push(
      {
        label: 'logout-separator',
        type: 'separator',
      },
      {
        label: formatMessage(messages.logout),
        type: 'text',
        onClick: () => handleLogout(),
        icon: 'logout',
      }
    );

    return items;
  }, [role, ign, uuid]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dropdown
      side="bottom"
      align="end"
      sideOffset={2}
      alignOffset={0}
      trigger={
        <div style={{ position: 'relative' }}>
          <IconButton
            name="settings"
            label={formatMessage({ id: 'icon.open_profile_dropdown', defaultMessage: 'Open profile dropdown' })}
            $color="base"
          />

          <NotificationsPill />
        </div>
      }
      items={dropdownItems}
    />
  );
};

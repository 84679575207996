import { Language, PatreonTier, Platform, TimeFormat, UserSetting } from '@idalon/api-client';
import { useMemo } from 'react';

export enum SettingKey {
  displayAliases = 'displayAliases',
  aliases = 'aliases',
  platform = 'platform',
  timeFormat = 'timeFormat',
  language = 'language',
  patreonTier = 'patreonTier',
}

interface ParsedSettings {
  [SettingKey.displayAliases]: boolean;
  [SettingKey.aliases]: string[];
  [SettingKey.platform]: Platform;
  [SettingKey.timeFormat]: TimeFormat;
  [SettingKey.language]: Language;
  [SettingKey.patreonTier]?: PatreonTier;
}

export const useUserSettings = (settings: UserSetting[] = []) => {
  return useMemo(
    () =>
      ({
        [SettingKey.displayAliases]: settings.find(({ key }) => key === SettingKey.displayAliases)?.value ?? false,
        [SettingKey.aliases]: settings.find(({ key }) => key === SettingKey.aliases)?.value ?? [],
        [SettingKey.platform]: settings.find(({ key }) => key === SettingKey.platform)?.value ?? Platform.pc,
        [SettingKey.timeFormat]: settings.find(({ key }) => key === SettingKey.timeFormat)?.value ?? TimeFormat._24h,
        [SettingKey.language]: settings.find(({ key }) => key === SettingKey.language)?.value ?? Language.en_GB,
        [SettingKey.patreonTier]: settings.find(({ key }) => key === SettingKey.patreonTier)?.value,
      } as ParsedSettings),
    [settings]
  );
};

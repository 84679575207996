import { SessionsApiFactory } from '@idalon/api-client';
import axios, { AxiosError } from 'axios';
import { get } from 'browser-cookies';

import { getFingerprint } from 'src/utils/helpers/fingerprint/getFingerprint';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.defaults.headers.common['x-access-token'] = get('at') ?? '';
axiosInstance.defaults.headers.common['x-refresh-token'] = get('rt') ?? '';

// This attempts to refresh the access token when a 403 or a 401 happens.
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const { config, response } = error;

    if (!response || ![401, 403].includes(response.status) || !config || config.retried) {
      return Promise.reject(error);
    }

    await refreshSession();

    // Retry the original request.
    return axiosInstance({
      ...config,
      retried: true,
      headers: { ...config.headers, 'x-access-token': get('at') ?? '' },
    });
  }
);

const refreshSession = async () => {
  // Refetch and save the access token.
  await SessionsApiFactory(undefined, '', axios).refreshSession(
    {
      refreshSessionRequest: { fingerprint: await getFingerprint() },
    },
    { withCredentials: true, baseURL: process.env.REACT_APP_API_URL, headers: { 'x-refresh-token': get('rt') ?? '' } }
  );

  axiosInstance.defaults.headers.common['x-access-token'] = get('at') ?? '';
  axiosInstance.defaults.headers.common['x-refresh-token'] = get('rt') ?? '';
};

export { axiosInstance, refreshSession };

import { ReactNode } from 'react';
import { styled } from 'styled-components';

import { FlexGroup } from 'src/elements/FlexGroup';
import { Text } from 'src/elements/Text';

const BaseAlign = styled.div`
  display: inline;
  flex: 0;
`;

interface Props {
  children: ReactNode;
  label: ReactNode;
  width?: number;
}

export const LabelledItem = ({ children, label, width = 1 }: Props) => {
  return (
    <FlexGroup $flex={width} $vertical $gap={4}>
      <Text style={{ whiteSpace: 'nowrap' }} $color="faded" $uppercase $size="small">
        {label}
      </Text>

      <BaseAlign>{children}</BaseAlign>
    </FlexGroup>
  );
};

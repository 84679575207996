import { UserRole } from '@idalon/api-client';
import { useQueryClient } from '@tanstack/react-query';
import { erase } from 'browser-cookies';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { axiosInstance } from 'src/api/axiosInstance';
import { queryKeys } from 'src/api/queryKeys';
import { useDeleteSessionMutation } from 'src/api/sessions/query';
import { messages } from 'src/assets/messages';
import { SignInDialog } from 'src/components/dialogs/SignInDialog';
import { Dropdown, DropdownItem } from 'src/components/ui/Dropdown';
import { IconButton } from 'src/elements/Icon';
import { useDialog } from 'src/utils/hooks/useDialog';
import { useSession } from 'src/utils/hooks/useSession';

export const MobileNavDropdown = () => {
  const { show } = useDialog(SignInDialog);
  const { formatMessage } = useIntl();
  const { isLoggedIn, user } = useSession();
  const navigate = useNavigate();
  const { mutateAsync: deleteSession } = useDeleteSessionMutation();
  const queryClient = useQueryClient();

  const handleLogout = async () => {
    try {
      await deleteSession({});

      erase('at');
      erase('rt');

      delete axiosInstance.defaults.headers.common['x-access-token'];
      delete axiosInstance.defaults.headers.common['x-refresh-token'];

      queryClient.resetQueries(queryKeys.profile());
      queryClient.invalidateQueries(queryKeys.nights());
    } catch {
      //
    }
  };

  const dropdownItems = useMemo(() => {
    const items: DropdownItem[] = [
      {
        label: formatMessage({ id: 'nav.leaderboards', defaultMessage: 'Leaderboards' }),
        type: 'text',
        onClick: () => navigate('/leaderboards'),
      },
      {
        label: formatMessage({ id: 'nav.games', defaultMessage: 'Games' }),
        type: 'submenu',
        items: [
          {
            type: 'text',
            label: formatMessage({ id: 'nav.raplak', defaultMessage: 'Raplak' }),
            onClick: () => navigate('/games/raplak'),
          },
          {
            type: 'text',
            label: formatMessage({ id: 'nav.propa', defaultMessage: 'Propa' }),
            onClick: () => navigate('/games/propa'),
          },
          {
            type: 'text',
            label: formatMessage({ id: 'nav.teralyst', defaultMessage: 'Teralyst' }),
            onClick: () => navigate('/games/teralyst'),
          },
        ],
      },
      {
        label: 'account-separator',
        type: 'separator',
      },
    ];

    if (isLoggedIn && user) {
      items.push({
        label: formatMessage(messages.account),
        type: 'text',
        icon: 'settings',
        onClick: () => navigate(`/account`),
      });

      if (user.role === UserRole.mod || user.role === UserRole.admin) {
        items.push({
          label: formatMessage({ id: 'nav.moderation', defaultMessage: 'Moderation' }),
          type: 'text',
          onClick: () => navigate(`/moderation`),
          icon: 'shield_person',
        });
      }

      if (user.ign) {
        items.push({
          label: formatMessage(messages.profile),
          type: 'text',
          onClick: () => navigate(`/users/${user.ign}`),
          icon: 'person',
        });
      }

      items.push(
        {
          label: 'logout-separator',
          type: 'separator',
        },
        {
          label: formatMessage(messages.logout),
          type: 'text',
          onClick: () => handleLogout(),
          icon: 'logout',
        }
      );
    }

    if (!isLoggedIn) {
      items.push({
        label: formatMessage(messages.sign_in),
        type: 'text',
        onClick: () => show({}),
        icon: 'login',
      });
    }

    return items;
  }, [isLoggedIn, user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dropdown
      side="bottom"
      align="end"
      sideOffset={2}
      alignOffset={-16}
      trigger={
        <div style={{ position: 'relative' }}>
          <IconButton
            name="menu"
            label={formatMessage({ id: 'icon.open_nav_menu', defaultMessage: 'Open navigation menu' })}
            $color="base"
          />
        </div>
      }
      items={dropdownItems}
    />
  );
};

import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'src/api/queryKeys';

import { utilsApi } from './api';

export const useGetBountyExpiryQuery = () =>
  useQuery({
    queryKey: queryKeys.bountyExpiry(),
    queryFn: () => utilsApi.getBountyExpiry(),
    select: (data) => data.data,
    refetchInterval: 300000,
  });

export const useGetPtReleasesQuery = () =>
  useQuery({
    queryKey: queryKeys.ptReleases(),
    queryFn: () => utilsApi.getPtReleases(),
    select: (data) => data.data,
    refetchInterval: 300000,
  });

import { css, styled } from 'styled-components';

import { FontSize, TextColor } from 'src/assets/theme';

interface Props {
  $color?: TextColor;
  $size?: FontSize;
  $bold?: boolean;
  $uppercase?: boolean;
  $underline?: boolean;
  $italic?: boolean;
  $centered?: boolean;
}

export const Text = styled.span<Props>`
  color: ${({ $color, theme }) => ($color ? theme.textColors[$color] : 'inherit')};
  font-size: ${({ $size, theme }) => ($size ? theme.fontSizes[$size] : 'inherit')};

  ${({ $bold }) => {
    if ($bold) {
      return css`
        font-weight: 600;
      `;
    }
  }}

  ${({ $uppercase }) => {
    if ($uppercase) {
      return css`
        text-transform: uppercase;
      `;
    }
  }}

  ${({ $underline }) => {
    if ($underline) {
      return css`
        text-decoration: underline;
      `;
    }
  }}

  ${({ $italic }) => {
    if ($italic) {
      return css`
        font-style: italic;
      `;
    }
  }}

  ${({ $centered }) => {
    if ($centered) {
      return css`
        text-align: center;
      `;
    }
  }}
`;

export const Code = styled(Text).attrs((props) => ({
  as: 'code',
  ...props,
}))<Props>`
  font-family: 'Inconsolata', monospace;
  padding: 2px 6px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.secondaryActive};
`;

export const Small = styled(Text).attrs((props) => ({
  as: 'small',
  ...props,
}))<Props>`
  font-size: ${({ $size, theme }) => theme.fontSizes[$size ?? 'small']};
`;

export const Caption = styled(Text).attrs((props) => ({
  role: 'heading',
  as: 'h4',
  ...props,
}))<Props>`
  font-size: ${({ $size, theme }) => theme.fontSizes[$size ?? 'caption']};
`;

export const Subheading = styled(Text).attrs((props) => ({
  role: 'heading',
  as: 'h3',
  ...props,
}))<Props>`
  font-size: ${({ $size, theme }) => theme.fontSizes[$size ?? 'subheading']};
`;

export const Heading = styled(Text).attrs((props) => ({
  role: 'heading',
  as: 'h2',
  ...props,
}))<Props>`
  font-size: ${({ $size, theme }) => theme.fontSizes[$size ?? 'heading']};
`;

export const Title = styled(Text).attrs((props) => ({
  role: 'heading',
  as: 'h1',
  ...props,
}))<Props>`
  font-size: ${({ $size, theme }) => theme.fontSizes[$size ?? 'title']};
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
`;

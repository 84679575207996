import * as RadixPopover from '@radix-ui/react-popover';
import { ReactNode } from 'react';
import { styled } from 'styled-components';

import { slideAndFade } from 'src/assets/keyframes';
import { animationTiming } from 'src/assets/mixins';

interface Props {
  children: ReactNode | ReactNode[];
  trigger: ReactNode;
  side?: 'top' | 'bottom' | 'right' | 'left';
  align?: 'start' | 'center' | 'end';
  sideOffset?: number;
  alignOffset?: number;
}

const Content = styled(RadixPopover.Content)`
  padding: 8px 12px;
  background: ${({ theme }) => theme.colors.secondary5};
  border-radius: 2px;

  animation: ${slideAndFade} 0.4s;
  will-change: transform, opacity;

  ${animationTiming}
`;

const Arrow = styled(RadixPopover.Arrow)`
  fill: ${({ theme }) => theme.colors.secondary5};
`;

export const Popover = ({
  children,
  trigger,
  side = 'top',
  align = 'center',
  sideOffset = 0,
  alignOffset = 0,
}: Props) => {
  return (
    <RadixPopover.Root>
      <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>

      <RadixPopover.Portal>
        <Content side={side} align={align} sideOffset={sideOffset} alignOffset={alignOffset}>
          <Arrow />

          {children}
        </Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
};

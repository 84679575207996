import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';

import { useGetNightsQuery } from 'src/api/nights/query';
import { NightSummary } from 'src/components/content/NightSummary';
import { Await } from 'src/components/state/Await';
import { Alert } from 'src/components/ui/Alert';
import { Pagination, usePagination } from 'src/components/ui/Pagination';
import { FlexGroup } from 'src/elements/FlexGroup';
import { Separator } from 'src/elements/Separator';
import { Subheading, Text } from 'src/elements/Text';
import { useSettings } from 'src/utils/hooks/useSettings';
import { useViewport } from 'src/utils/hooks/useViewport';

import { EidolonClock } from './EidolonClock';
import { TotalHunts } from './TotalHunts';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Home = () => {
  const { isMobile } = useViewport();
  const { limit, offset, onPageChange } = usePagination({ limit: 7, offset: 0 });
  const { notificationTitle, notificationBody, notificationActive } = useSettings();

  const queryResult = useGetNightsQuery({ limit, offset });

  return (
    <Container>
      <Helmet>
        <title>idalon.com ~ home</title>
      </Helmet>

      <FlexGroup $flex={0} style={{ marginBottom: 64 }} $gap={16} $stretch $vertical={isMobile}>
        <EidolonClock />

        <Separator />

        <TotalHunts />
      </FlexGroup>

      {notificationActive && (
        <div style={{ marginBottom: 64 }}>
          <Alert id={notificationTitle} title={notificationTitle}>
            <Text $color="base">{notificationBody}</Text>
          </Alert>
        </div>
      )}

      <Subheading style={{ marginBottom: 24 }} $color="base">
        <FormattedMessage id="home.recent_hunts" defaultMessage="Recent hunts" />
      </Subheading>

      <FlexGroup $flex={0} $vertical $stretch $gap={8}>
        <Await queryResult={queryResult} contentDimensions={{ x: 1, y: 75.5 }} cardCount={7}>
          {({ items }) => (
            <FlexGroup $vertical $stretch $gap={8}>
              {items.map((night) => (
                <NightSummary key={night.uuid} night={night} />
              ))}
            </FlexGroup>
          )}
        </Await>

        <Pagination limit={limit} offset={offset} total={queryResult.data?.total ?? 0} onPageChange={onPageChange} />
      </FlexGroup>
    </Container>
  );
};

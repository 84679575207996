import { Fragment, ReactNode, useEffect } from 'react';

import { useGetProfileQuery } from 'src/api/me/query';
import { LoadingOverlay } from 'src/components/ui/Loading';
import { useSession } from 'src/utils/hooks/useSession';

interface Props {
  children: ReactNode[] | ReactNode;
}

export const SessionProvider = ({ children }: Props) => {
  const { loginAs, logout, user: storedUser } = useSession();

  const { data: user, isLoading } = useGetProfileQuery();

  useEffect(() => {
    if (user === storedUser || isLoading) {
      return;
    }

    user ? loginAs(user) : logout();
  }, [user, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <LoadingOverlay $opacity={isLoading ? 1 : 0} />

      {isLoading || children}
    </Fragment>
  );
};

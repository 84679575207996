import { useAtom } from 'jotai';

import { activeDialogAtom } from 'src/utils/atoms/ui';

export const DialogProvider = () => {
  const [{ Component, props }] = useAtom(activeDialogAtom);

  if (!Component) {
    return null;
  }

  return <Component {...props} />;
};

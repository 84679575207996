import { useAtom } from 'jotai';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { styled } from 'styled-components';

import { FlexGroup, Spacer } from 'src/elements/FlexGroup';
import { Icon, IconButton } from 'src/elements/Icon';
import { Text } from 'src/elements/Text';
import { dismissedAlertsAtom } from 'src/utils/atoms/ui';

const Container = styled.div.attrs({ role: 'alert' })`
  border: 1px solid ${({ theme }) => theme.colors.secondary20};
  border-top: 3px solid transparent;
  padding: 16px;
  border-radius: 2px;
  position: relative;

  &:before {
    content: '';
    height: 3px;
    top: -3px;
    left: -1px;
    width: calc(100% + 2px);
    position: absolute;
    background: ${({ theme }) => theme.colors.info};
  }
`;

interface Props {
  id: string;
  children: ReactNode | ReactNode[];
  title: ReactNode;
}

export const Alert = ({ id, children, title }: Props) => {
  const { formatMessage } = useIntl();
  const [{ ids }, setDismissedAlerts] = useAtom(dismissedAlertsAtom);

  const handleDismiss = () => {
    setDismissedAlerts({ ids: [...ids, id] });
  };

  const handleOpen = () => {
    setDismissedAlerts({ ids: ids.filter((key) => key !== id) });
  };

  const isOpen = !ids.includes(id);

  return (
    <Container>
      <FlexGroup style={{ marginBottom: isOpen ? 16 : 0 }} $gap={4}>
        <Icon
          $size="caption"
          $color="base"
          name="info"
          label={formatMessage({ id: 'icon.alert_info', defaultMessage: 'Info alert' })}
        />

        <Text $size="caption" $color="base">
          {title}
        </Text>

        <Spacer />

        {isOpen ? (
          <IconButton
            $color="base"
            name="close"
            label={formatMessage({ id: 'icon.dismiss_alert', defaultMessage: 'Dismiss alert' })}
            onClick={handleDismiss}
          />
        ) : (
          <IconButton
            $color="base"
            name="expand_more"
            label={formatMessage({ id: 'icon.open_alert', defaultMessage: 'Open alert' })}
            onClick={handleOpen}
          />
        )}
      </FlexGroup>

      {isOpen && children}
    </Container>
  );
};

import { useDebounce } from '@uidotdev/usehooks';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { useGetUsersQuery } from 'src/api/users/query';
import { Await } from 'src/components/state/Await';
import { Popover } from 'src/components/ui/Popover';
import { IconButton } from 'src/elements/Icon';
import { Input } from 'src/elements/Input';
import { List } from 'src/elements/List';
import { Text } from 'src/elements/Text';

const Result = styled(Link)`
  padding: 4px 8px;
  border-radius: 2px;
  transition: background 0.2s;
  margin: 0 -8px;

  &:hover {
    background: ${({ theme }) => theme.colors.secondaryHover};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary};
  }
`;

const NoResults = styled.span`
  padding: 4px 8px;
  border-radius: 2px;
  margin: 0 -8px;
`;

export const HunterSearch = () => {
  const [search, setSearch] = useState('');
  const deboundedSearch = useDebounce(search, 300);
  const { formatMessage } = useIntl();

  const usersQuery = useGetUsersQuery({ limit: 5, search: deboundedSearch, enabled: !!deboundedSearch });

  return (
    <Popover
      side="bottom"
      align="end"
      sideOffset={2}
      alignOffset={0}
      trigger={
        <IconButton
          name="search"
          label={formatMessage({ id: 'icon.hunter_search', defaultMessage: 'Hunter search' })}
          $color="base"
        />
      }
    >
      <Input
        style={{ width: 200, marginBottom: !!search ? 12 : 0 }}
        placeholder={formatMessage({ id: 'hunter_search.placeholder', defaultMessage: 'Search a hunter' })}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      {search && (
        <Await queryResult={usersQuery} contentDimensions={{ x: 1, y: 25.5 }} cardCount={1}>
          {({ items, total }) => (
            <List $pointless style={{ gap: 2, marginBottom: -4 }}>
              {items.map(({ ign, uuid }) => (
                <Result key={uuid} to={`/users/${ign}`}>
                  <Text $color="base">{ign}</Text>
                </Result>
              ))}

              {total === 0 && (
                <NoResults>
                  <Text $color="faded" $italic>
                    <FormattedMessage id="hunter_search.no_results" defaultMessage="No hunters found" />
                  </Text>
                </NoResults>
              )}
            </List>
          )}
        </Await>
      )}
    </Popover>
  );
};

import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  account: {
    id: 'account',
    defaultMessage: 'Account',
  },
  active_time: {
    id: 'active_time',
    defaultMessage: 'Active time',
  },
  approved: {
    id: 'approved',
    defaultMessage: 'Approved',
  },
  anonymous: {
    id: 'anonymous',
    defaultMessage: 'anon',
  },
  average_last_limb_time: {
    id: 'average_last_limb_time',
    defaultMessage: 'Average last limb time',
  },
  average_real_time: {
    id: 'average_real_time',
    defaultMessage: 'Average real time',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel',
  },
  cancelled: {
    id: 'cancelled',
    defaultMessage: 'Cancelled',
  },
  client: {
    id: 'client',
    defaultMessage: 'Client',
  },
  close: {
    id: 'close',
    defaultMessage: 'Close',
  },
  count_results: {
    id: 'count_results',
    defaultMessage: '{count, plural, =1 {1 result} other {# results}}',
  },
  created_at: {
    id: 'created_at',
    defaultMessage: 'Created at',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Delete',
  },
  description: {
    id: 'description',
    defaultMessage: 'Description',
  },
  last_limb_time: {
    id: 'last_limb_time',
    defaultMessage: 'Last limb time',
  },
  format: {
    id: 'format',
    defaultMessage: 'Format',
  },
  friends: {
    id: 'friends',
    defaultMessage: 'Friends',
  },
  host: {
    id: 'host',
    defaultMessage: 'Host',
  },
  hunters: {
    id: 'hunters',
    defaultMessage: 'Hunters',
  },
  ign: {
    id: 'ign',
    defaultMessage: 'IGN',
  },
  language: {
    id: 'language',
    defaultMessage: 'Language',
  },
  loading: {
    id: 'loading',
    defaultMessage: 'Loading',
  },
  logout: {
    id: 'logout',
    defaultMessage: 'Logout',
  },
  name: {
    id: 'name',
    defaultMessage: 'idalon.com',
  },
  night: {
    id: 'night',
    defaultMessage: 'Night',
  },
  nights: {
    id: 'nights',
    defaultMessage: 'Nights',
  },
  pending: {
    id: 'pending',
    defaultMessage: 'Pending',
  },
  platform_pc: {
    id: 'platform_pc',
    defaultMessage: 'PC',
  },
  platform_xbox: {
    id: 'platform_xbox',
    defaultMessage: 'Xbox',
  },
  platform_ps: {
    id: 'platform_ps',
    defaultMessage: 'PlayStation',
  },
  preferences: {
    id: 'preferences',
    defaultMessage: 'Preferences',
  },
  private: {
    id: 'private',
    defaultMessage: 'Private',
  },
  profile: {
    id: 'profile',
    defaultMessage: 'Profile',
  },
  public: {
    id: 'public',
    defaultMessage: 'Public',
  },
  recommended: {
    id: 'recommended',
    defaultMessage: 'Recommended',
  },
  real_time: {
    id: 'real_time',
    defaultMessage: 'Real time',
  },
  rejected: {
    id: 'rejected',
    defaultMessage: 'Rejected',
  },
  runs: {
    id: 'runs',
    defaultMessage: 'Runs',
  },
  save: {
    id: 'save',
    defaultMessage: 'Save',
  },
  settings: {
    id: 'settings',
    defaultMessage: 'Settings',
  },
  show: {
    id: 'show',
    defaultMessage: 'Show',
  },
  sign_in: {
    id: 'sign_in',
    defaultMessage: 'Sign in',
  },
  squad_any: {
    id: 'squad_any',
    defaultMessage: 'Any',
  },
  squad_full: {
    id: 'squad_full',
    defaultMessage: 'Squad',
  },
  squad_trio: {
    id: 'squad_trio',
    defaultMessage: 'Trio',
  },
  squad_duo: {
    id: 'squad_duo',
    defaultMessage: 'Duo',
  },
  squad_solo: {
    id: 'squad_solo',
    defaultMessage: 'Solo',
  },
  submit: {
    id: 'submit',
    defaultMessage: 'Submit',
  },
  unlisted: {
    id: 'unlisted',
    defaultMessage: 'Unlisted',
  },
});

import { UserRole } from '@idalon/api-client';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { createBrowserRouter, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { NotFound } from 'src/pages/fallback/NotFound';
import { SomethingWentWrong } from 'src/pages/fallback/SomethingWentWrong';
import { Home } from 'src/pages/home/Home';

import { MainLayout } from './layouts/MainLayout/MainLayout';
import { Authenticated } from './utils/guards/Authenticated';
import { useToast } from './utils/hooks/useToast';

export const router = createBrowserRouter([
  {
    path: '',
    element: <MainLayout />,
    ErrorBoundary: SomethingWentWrong,
    children: [
      {
        path: '',
        element: <Home />,
        ErrorBoundary: SomethingWentWrong,
      },
      {
        path: '/nights/:nightUuid',
        lazy: () =>
          import('src/pages/nights/Night').then(({ Night }) => ({
            element: <Night />,
          })),
        ErrorBoundary: SomethingWentWrong,
      },
      {
        path: '/users/:userUuid',
        lazy: () =>
          import('src/pages/users/User').then(({ User }) => ({
            element: <User />,
          })),
        ErrorBoundary: SomethingWentWrong,
      },
      {
        path: '/hunters/:userUuid',
        Component: () => {
          const { userUuid } = useParams();
          const navigate = useNavigate();

          useEffect(() => {
            navigate(`/users/${userUuid}`);
          }, [navigate, userUuid]);

          return null;
        },
      },
      {
        path: '/tools/analyzer',
        Component: () => {
          const navigate = useNavigate();

          useEffect(() => {
            navigate(`/upload`);
          }, [navigate]);

          return null;
        },
      },
      {
        path: '/tools/analyzer/:nightUuid',
        Component: () => {
          const { nightUuid } = useParams();
          const navigate = useNavigate();

          useEffect(() => {
            navigate(`/nights/${nightUuid}`);
          }, [navigate, nightUuid]);

          return null;
        },
      },
      {
        path: '/auth',
        Component: () => {
          const navigate = useNavigate();
          const [params] = useSearchParams();
          const { success, error } = useToast();
          const { formatMessage } = useIntl();

          useEffect(() => {
            if (!params.has('error')) {
              success(formatMessage({ id: 'auth.login.success', defaultMessage: 'Login successful' }));
            }

            if (params.has('error')) {
              error(
                formatMessage(
                  { id: 'auth.login.error', defaultMessage: 'Login procedure failed: {error}' },
                  { error: params.get('error') }
                )
              );
            }

            navigate(params.get('redirectTo') ?? '/');
          }, [navigate]); // eslint-disable-line react-hooks/exhaustive-deps

          return null;
        },
      },
      {
        path: '/upload',
        lazy: () =>
          import('src/pages/upload/Upload').then(({ Upload }) => ({
            element: <Upload />,
          })),
        ErrorBoundary: SomethingWentWrong,
      },
      {
        path: '/upload/:nightUuid',
        lazy: () =>
          import('src/pages/upload/UploadStatus').then(({ UploadStatus }) => ({
            element: <UploadStatus />,
          })),
        ErrorBoundary: SomethingWentWrong,
      },
      {
        path: '/leaderboards',
        lazy: () =>
          import('src/pages/leaderboards/Leaderboards').then(({ Leaderboards }) => ({
            element: <Leaderboards />,
          })),
        ErrorBoundary: SomethingWentWrong,
      },
      {
        path: '/account',
        lazy: () =>
          import('src/pages/account/Account').then(({ Account }) => ({
            element: <Authenticated children={<Account />} />,
          })),
        ErrorBoundary: SomethingWentWrong,
      },
      {
        path: '/moderation',
        lazy: () =>
          import('src/pages/moderation/Moderation').then(({ Moderation }) => ({
            element: <Authenticated role={UserRole.mod} children={<Moderation />} />,
          })),
        ErrorBoundary: SomethingWentWrong,
      },
      {
        path: '/games/propa',
        lazy: () =>
          import('src/pages/games/Propa').then(({ Propa }) => ({
            element: <Propa />,
          })),
        ErrorBoundary: SomethingWentWrong,
      },
      {
        path: '/games/raplak',
        lazy: () =>
          import('src/pages/games/Raplak').then(({ Raplak }) => ({
            element: <Raplak />,
          })),
        ErrorBoundary: SomethingWentWrong,
      },
      {
        path: '/games/teralyst',
        lazy: () =>
          import('src/pages/games/Teralyst').then(({ Teralyst }) => ({
            element: <Teralyst />,
          })),
        ErrorBoundary: SomethingWentWrong,
      },
      {
        path: '/pt',
        lazy: () =>
          import('src/pages/pt/ProfitTaker').then(({ ProfitTaker }) => ({
            element: <ProfitTaker />,
          })),
        ErrorBoundary: SomethingWentWrong,
      },
      {
        path: '/rules',
        lazy: () =>
          import('src/pages/rules/Rules').then(({ Rules }) => ({
            element: <Rules />,
          })),
        ErrorBoundary: SomethingWentWrong,
      },
      {
        path: '/database',
        lazy: () =>
          import('src/pages/database/Database').then(({ Database }) => ({
            element: <Database />,
          })),
        ErrorBoundary: SomethingWentWrong,
      },
      {
        path: '*',
        element: <NotFound />,
        ErrorBoundary: SomethingWentWrong,
      },
    ],
  },
]);

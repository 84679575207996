import { useMutation, useQuery } from '@tanstack/react-query';

import { queryKeys } from 'src/api/queryKeys';

import { sessionApi } from './api';

export const useGetSessionsQuery = () =>
  useQuery({
    queryKey: queryKeys.sessions(),
    queryFn: () => sessionApi.getSessions(),
    select: (data) => data.data,
    staleTime: 300000,
  });

export const useDeleteSessionMutation = () => useMutation({ mutationFn: sessionApi.deleteSession });

import * as RadixToast from '@radix-ui/react-toast';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { styled } from 'styled-components';

import { fadeOut, rollOut, slideUpAndFade, swipeOut } from 'src/assets/keyframes';
import { animationTiming, boxShadow } from 'src/assets/mixins';
import { FlexGroup, Spacer } from 'src/elements/FlexGroup';
import { IconButton } from 'src/elements/Icon';
import { Text } from 'src/elements/Text';

const Root = styled(RadixToast.Root)<{ $variant: ToastVariant }>`
  padding: 16px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background: ${({ theme, $variant }) => theme.colors[$variant]};
  position: relative;
  will-change: transform, opacity;
  border-top: 3px solid ${({ theme }) => theme.colors.secondary35};

  &:before {
    content: '';
    height: 3px;
    top: -3px;
    right: 0;
    position: absolute;
    background: ${({ theme }) => theme.colors.secondary50};
    animation: ${rollOut} 5s linear;
  }

  &[data-state='open'] {
    animation-name: ${slideUpAndFade};
  }

  &[data-state='closed'] {
    animation-name: ${fadeOut};
  }

  &[data-swipe='move'] {
    transform: translateX(var(--radix-toast-swipe-move-x));
  }

  &[data-swipe='cancel'] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
  }

  &[data-swipe='end'] {
    animation: ${swipeOut} 100ms ease-out;
  }

  ${animationTiming}
  animation-duration: 200ms;

  ${boxShadow}
`;

const Close = styled(RadixToast.Close)`
  position: absolute;
  right: 12px;
`;

export enum ToastVariant {
  success = 'success',
  error = 'error',
  info = 'info',
}

export interface ToastItem {
  id: string;
  message: ReactNode;
  variant?: ToastVariant;
}

export interface Props {
  children: ReactNode;
  variant?: ToastVariant;
}

export const Toast = ({ children, variant = ToastVariant.success }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Root duration={5000} aria-label={`toast-${variant}`} $variant={variant}>
      <RadixToast.Description>
        <FlexGroup>
          <Text $color="base">{children}</Text>

          <Spacer />

          <Close asChild>
            <IconButton
              $color="base"
              name="close"
              label={formatMessage({ id: 'icon.dismiss_toast', defaultMessage: 'Dismiss toast' })}
            />
          </Close>
        </FlexGroup>
      </RadixToast.Description>
    </Root>
  );
};

import { useState } from 'react';
import { useIntl } from 'react-intl';

import { FlexGroup, Spacer } from 'src/elements/FlexGroup';
import { IconButton } from 'src/elements/Icon';

export interface PaginationState {
  limit: number;
  offset: number;
}

interface Props {
  limit: number;
  offset: number;
  total: number;
  onPageChange: (state: PaginationState) => void;
}

export const Pagination = ({ limit, offset, total, onPageChange }: Props) => {
  const { formatMessage } = useIntl();

  const lastPage = total === 0 ? 1 : Math.ceil(total / limit);
  const isFirstPage = offset === 0;
  const isLastPage = offset === (lastPage - 1) * limit;

  return (
    <FlexGroup $gap={4}>
      <IconButton
        onClick={() => onPageChange({ limit, offset: 0 })}
        name="first_page"
        label={formatMessage({ id: 'icon.first_page', defaultMessage: 'First page' })}
        $color="faded"
        disabled={isFirstPage}
      />

      <IconButton
        onClick={() => onPageChange({ limit, offset: offset - limit })}
        name="chevron_left"
        label={formatMessage({ id: 'icon.previous_page', defaultMessage: 'Previous page' })}
        $color="faded"
        disabled={isFirstPage}
      />

      <Spacer />

      <IconButton
        onClick={() => onPageChange({ limit, offset: offset + limit })}
        name="chevron_right"
        label={formatMessage({ id: 'icon.next_page', defaultMessage: 'Next page' })}
        $color="faded"
        disabled={isLastPage}
      />

      <IconButton
        onClick={() => onPageChange({ limit, offset: limit * (lastPage - 1) })}
        name="last_page"
        label={formatMessage({ id: 'icon.last_page', defaultMessage: 'Last page' })}
        $color="faded"
        disabled={isLastPage}
      />
    </FlexGroup>
  );
};

export const usePagination = (state: PaginationState = { limit: 5, offset: 0 }) => {
  const [{ limit, offset }, setPagination] = useState<PaginationState>(state);

  return {
    limit,
    offset,
    onPageChange: setPagination,
  };
};

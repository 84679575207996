import { NightOrderByColumns, NightsApiGetNightsRequest, OrderDirection } from '@idalon/api-client';
import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'src/api/queryKeys';

import { nightsApi } from './api';

export const useGetNightsQuery = ({
  limit = 5,
  offset = 0,
  orderBy = NightOrderByColumns.createdAt,
  orderDirection = OrderDirection.desc,
  userUuid,
  capturedHydrolystsCount,
  squadSize,
  verifiedOnly,
  season,
}: NightsApiGetNightsRequest) =>
  useQuery({
    queryKey: queryKeys.nights({
      limit,
      offset,
      orderBy,
      orderDirection,
      userUuid,
      capturedHydrolystsCount,
      squadSize,
      verifiedOnly,
      season,
    }),
    queryFn: () =>
      nightsApi.getNights({
        limit,
        offset,
        orderBy,
        orderDirection,
        userUuid,
        capturedHydrolystsCount,
        squadSize,
        verifiedOnly,
        season,
      }),
    select: (data) => data.data,
    keepPreviousData: true,
  });

export const useGetNightQuery = ({ nightUuid }: { nightUuid: string }) =>
  useQuery({
    queryKey: queryKeys.night({ nightUuid }),
    queryFn: () => nightsApi.getNight({ nightUuid }),
    select: (data) => data.data,
    staleTime: 300000,
  });

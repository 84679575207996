import { OrderDirection, UserOrderByColumns, UsersApiGetUsersRequest } from '@idalon/api-client';
import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'src/api/queryKeys';

import { usersApi } from './api';

export const useGetUserQuery = ({ userUuid }: { userUuid: string }) =>
  useQuery({
    queryKey: queryKeys.user({ userUuid }),
    queryFn: () => usersApi.getUser({ userUuidOrIgn: userUuid }),
    select: (data) => data.data,
    staleTime: 300000,
  });

export const useGetUsersQuery = ({
  limit = 5,
  offset = 0,
  orderBy = UserOrderByColumns.allHydrolystCaptures,
  orderDirection = OrderDirection.desc,
  season,
  search,
  enabled = true,
}: UsersApiGetUsersRequest & { enabled?: boolean }) =>
  useQuery({
    queryKey: queryKeys.users({ limit, offset, orderBy, orderDirection, search, season }),
    queryFn: () => usersApi.getUsers({ limit, offset, orderBy, orderDirection, search, season }),
    select: (data) => data.data,
    keepPreviousData: true,
    enabled,
  });

import {
  MeApiGetUserEligibleNightsRequest,
  MeApiGetUserNightsRequest,
  NightOrderByColumns,
  OrderDirection,
  VerificationStatus,
} from '@idalon/api-client';
import { useMutation, useQuery } from '@tanstack/react-query';

import { queryKeys } from 'src/api/queryKeys';

import { authApi, meApi } from './api';

export const useGetProfileQuery = () =>
  useQuery({
    queryKey: queryKeys.profile(),
    queryFn: () => meApi.getProfile(),
    select: (data) => data.data,
  });

export const useGetUserNightsQuery = ({
  limit = 10,
  offset = 0,
  orderBy = NightOrderByColumns.createdAt,
  orderDirection = OrderDirection.desc,
  capturedHydrolystsCount,
  squadSize,
  verifiedOnly,
  season,
  withRuns,
  withVerification,
  description,
}: MeApiGetUserNightsRequest) =>
  useQuery({
    queryKey: queryKeys.userNights({
      limit,
      offset,
      orderBy,
      orderDirection,
      capturedHydrolystsCount,
      squadSize,
      verifiedOnly,
      season,
      withRuns,
      withVerification,
      description,
    }),
    queryFn: () =>
      meApi.getUserNights({
        limit,
        offset,
        orderBy,
        orderDirection,
        capturedHydrolystsCount,
        squadSize,
        verifiedOnly,
        season,
        withRuns,
        withVerification,
        description,
      }),
    select: (data) => data.data,
    keepPreviousData: true,
  });

export const useGetEligibleNightsQuery = ({
  limit = 5,
  offset = 0,
  orderBy = NightOrderByColumns.createdAt,
  orderDirection = OrderDirection.desc,
  withRuns,
  withEidolons,
}: MeApiGetUserEligibleNightsRequest) =>
  useQuery({
    queryKey: queryKeys.eligibleNights({
      limit,
      offset,
      orderBy,
      orderDirection,
      withRuns,
      withEidolons,
    }),
    queryFn: () =>
      meApi.getUserEligibleNights({
        limit,
        offset,
        orderBy,
        orderDirection,
        withRuns,
        withEidolons,
      }),
    select: (data) => data.data,
    keepPreviousData: true,
  });

export const useGetUserNightVerificationsQuery = ({
  limit,
  offset,
  status,
}: {
  limit?: number;
  offset?: number;
  status?: VerificationStatus;
}) =>
  useQuery({
    queryKey: queryKeys.userNightVerifications({ limit, offset, status }),
    queryFn: () => meApi.getUserVerifications({ limit, offset, status }),
    select: (data) => data.data,
    keepPreviousData: true,
  });

export const useGetAuthProvidersQuery = () =>
  useQuery({
    queryKey: queryKeys.authProviders(),
    queryFn: () => meApi.getAuthProviders(),
    select: (data) => data.data,
  });

export const useUnlinkAuthProviderMutation = () =>
  useMutation({
    mutationFn: ({ provider }: { provider: string }) =>
      provider === 'steam' ? authApi.steamUnlink() : authApi.patreonUnlink(),
  });

export const useGetLinkTokenMutation = () => useMutation({ mutationFn: authApi.getLinkToken });

export const useUpdateProfileMutation = () => useMutation({ mutationFn: meApi.updateProfile });

export const useUpdateUserSettingsMutation = () => useMutation({ mutationFn: meApi.updateUserSettings });

export const useUpdateNightMutation = () => useMutation({ mutationFn: meApi.updateNight });

export const useDeleteUserNightMutation = () => useMutation({ mutationFn: meApi.deleteUserNight });

export const useRequestNightVerificationMutation = () => useMutation({ mutationFn: meApi.requestNightVerification });

import { Night } from '@idalon/api-client';

export const parseFormat = (night: Night) => {
  const fullSets = night.capturedHydrolystsCount;
  const gantulystsOnly = night.capturedGantulystsCount - fullSets;
  const teralystsOnly = night.capturedTeralystsCount - gantulystsOnly - fullSets;

  return `${fullSets}x3${gantulystsOnly > 0 ? `+${gantulystsOnly}x2` : ''}${
    teralystsOnly > 0 ? `+${teralystsOnly}` : ''
  }`;
};

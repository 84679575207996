import { params } from 'src/utils/helpers/query/params';

export const queryKeys = {
  // /sessions.
  sessions: params('Sessions'),
  // /@me
  profile: params('Profile'),
  userNights: params('UserNights'),
  eligibleNights: params('EligibleNights'),
  userNightVerifications: params('UserNightVerifications'),
  authProviders: params('AuthProviders'),
  // /nights
  nights: params('Nights'),
  night: params('Night'),
  // /runs
  runs: params('Runs'),
  // /users
  user: params('User'),
  users: params('Users'),
  // /stats
  stats: params('Stats'),
  // /settings
  publicSettings: params('PublicSettings'),
  // /@admin
  moderatorNightVerifications: params('ModeratorNightVerifications'),
  adminSettings: params('AdminSettings'),
  // /utils
  bountyExpiry: params('BountyExpiry'),
  ptReleases: params('PtReleases'),
  // /upload
  uploadStatus: params('UploadStatus'),
  // /translations
  translations: params('Translations'),
};

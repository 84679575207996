import { TimeFormat } from '@idalon/api-client';
import { Duration, format, intervalToDuration } from 'date-fns';
import { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';

import { useGetBountyExpiryQuery } from 'src/api/utils/query';
import { Await } from 'src/components/state/Await';
import { Ago } from 'src/components/ui/Ago';
import { FlexGroup } from 'src/elements/FlexGroup';
import { Text } from 'src/elements/Text';
import { useSession } from 'src/utils/hooks/useSession';
import { useUserSettings } from 'src/utils/hooks/useUserSettings';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;
`;

const CETUS_DAY_DURATION = 100 * 60 * 1000;
const CETUS_NIGHT_DURATION = 50 * 60 * 1000;

const TimeUntil = ({ expiryDate }: { expiryDate: number }) => {
  const calculateDuration = () => {
    const nightEnd = expiryDate < Date.now() ? expiryDate + CETUS_DAY_DURATION + CETUS_NIGHT_DURATION : expiryDate;
    const isDay = nightEnd - Date.now() > CETUS_NIGHT_DURATION;

    return [
      isDay,
      intervalToDuration({
        start: new Date(isDay ? nightEnd - CETUS_NIGHT_DURATION : nightEnd),
        end: new Date(),
      }),
    ] as [boolean, Duration];
  };

  const [[isDay, { hours, minutes, seconds }], setDuration] = useState<[boolean, Duration]>(calculateDuration());

  useEffect(() => {
    const interval = setInterval(() => setDuration(calculateDuration()), 1000);

    return () => clearInterval(interval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <Text $color="faded">
        {isDay ? (
          <FormattedMessage id="home.time_until_night" defaultMessage="Time until night" />
        ) : (
          <FormattedMessage id="home.time_until_day" defaultMessage="Time until day" />
        )}
      </Text>

      <Text $size="subheading" $color="base">
        {hours}:{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
      </Text>
    </Fragment>
  );
};

const UpcomingNights = ({ expiryDate }: { expiryDate: number }) => {
  const { user } = useSession();
  const { timeFormat } = useUserSettings(user?.settings);

  const calculateUpcomingNights = () => {
    const closestNightStart =
      expiryDate - CETUS_NIGHT_DURATION < Date.now()
        ? expiryDate + CETUS_DAY_DURATION
        : expiryDate - CETUS_NIGHT_DURATION;

    return Array(5)
      .fill(null)
      .map((_, key) => new Date(closestNightStart + (CETUS_DAY_DURATION + CETUS_NIGHT_DURATION) * key));
  };

  const [upcomingNights, setUpcomingNights] = useState<Date[]>(calculateUpcomingNights());

  useEffect(() => {
    const interval = setInterval(() => setUpcomingNights(calculateUpcomingNights()), 1000);

    return () => clearInterval(interval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <Text $color="faded">
        <FormattedMessage id="home.upcoming_nights" defaultMessage="Upcoming nights" />
      </Text>

      <ul>
        {upcomingNights.map((nightStart) => (
          <li key={nightStart.toString()}>
            <FlexGroup>
              <Text style={{ width: 70, textAlign: 'right' }} $color="base">
                {format(nightStart, timeFormat === TimeFormat._24h ? 'HH:mm' : 'h:mm a')}
              </Text>

              <Text $color="faded">
                &nbsp;&middot;&nbsp;
                <Ago date={nightStart} />
              </Text>
            </FlexGroup>
          </li>
        ))}
      </ul>
    </Fragment>
  );
};

export const EidolonClock = () => {
  const queryResult = useGetBountyExpiryQuery();

  return (
    <Container>
      <Await queryResult={queryResult} cardCount={1} contentDimensions={{ x: 1, y: 200.5 }}>
        {({ expiryDate }) => (
          <Fragment>
            <TimeUntil expiryDate={expiryDate} />

            <UpcomingNights expiryDate={expiryDate} />
          </Fragment>
        )}
      </Await>
    </Container>
  );
};

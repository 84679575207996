import * as RadixDialog from '@radix-ui/react-dialog';
import { useAtom } from 'jotai';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { css, styled } from 'styled-components';

import { fadeIn, slideAndFade } from 'src/assets/keyframes';
import { animationTiming, boxShadow } from 'src/assets/mixins';
import { IconButton } from 'src/elements/Icon';
import { Subheading, Text } from 'src/elements/Text';
import { activeDialogAtom } from 'src/utils/atoms/ui';

interface Props {
  title: ReactNode;
  children: (controls: { hide: () => void }) => ReactNode | ReactNode[];
  description?: ReactNode;
}

const Content = styled(RadixDialog.Content)`
  width: 500px;
  background: ${({ theme }) => theme.colors.light};
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 20%;
  left: calc(50% - 250px);
  padding: 16px;

  animation: ${slideAndFade} 0.15s;
  will-change: transform, opacity;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.deviceWidths.tablet}px) {
      left: 8px;
      width: calc(100% - 16px);
    }
  `}

  ${animationTiming}
  ${boxShadow}
`;

const Overlay = styled(RadixDialog.Overlay)`
  background-color: rgba(22, 23, 24, 0.4);
  position: fixed;
  inset: 0;

  animation: ${fadeIn} 0.15s;
  will-change: opacity;

  ${animationTiming}
`;

const Title = styled(RadixDialog.Title)`
  margin-bottom: 16px;
`;

const Description = styled(RadixDialog.Description)`
  margin-bottom: 16px;
`;

const Close = styled(RadixDialog.Close)`
  position: absolute;
  top: 6px;
  right: 6px;
`;

export const Dialog = ({ title, description, children }: Props) => {
  const [, setActiveDialog] = useAtom(activeDialogAtom);
  const { formatMessage } = useIntl();

  const hide = () => {
    setActiveDialog({ Component: null, props: {} });
  };

  return (
    <RadixDialog.Root open>
      <RadixDialog.Portal>
        <Overlay onClick={hide} />

        <Content>
          <Subheading as={Title} $color="base">
            {title}
          </Subheading>

          {description && (
            <Text as={Description} $color="base">
              {description}
            </Text>
          )}

          {children({ hide })}

          <Close asChild onClick={hide}>
            <IconButton
              name="close"
              label={formatMessage({ id: 'icon.close_dialog', defaultMessage: 'Close dialog' })}
              $color="base"
            />
          </Close>
        </Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};

export enum Locale {
  en = 'en',
  es = 'es',
  de_DE = 'de-DE',
  cs = 'cs',
  ru_RU = 'ru-RU',
  uk_UA = 'uk-UA',
  tl = 'tl',
  zh_HK = 'zh-HK',
  ja = 'ja',
}

export const localeMap: Record<Locale, { name: string }> = {
  [Locale.en]: { name: 'English' },
  [Locale.es]: { name: 'Español' },
  [Locale.de_DE]: { name: 'Deutsch' },
  [Locale.cs]: { name: 'Česky' },
  [Locale.ru_RU]: { name: 'Русский' },
  [Locale.uk_UA]: { name: 'Українська' },
  [Locale.tl]: { name: 'Tagalog' },
  [Locale.zh_HK]: { name: '简体中文' },
  [Locale.ja]: { name: '日本語' },
};

import { Fragment } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { useGetStatsQuery } from 'src/api/stats/query';
import { Await } from 'src/components/state/Await';
import { Button } from 'src/elements/Button';
import { FlexGroup } from 'src/elements/FlexGroup';
import { Icon } from 'src/elements/Icon';
import { Text } from 'src/elements/Text';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;
`;

export const TotalHunts = () => {
  const queryResult = useGetStatsQuery({});
  const { formatMessage } = useIntl();

  return (
    <Container>
      <Await queryResult={queryResult} cardCount={1} contentDimensions={{ x: 1, y: 200.5 }}>
        {({ nights }) => (
          <Fragment>
            <Text $color="faded">
              <FormattedMessage id="home.already_analyzed" defaultMessage="We've already analyzed" />
            </Text>

            <Text $size="subheading" $color="base">
              <FormattedMessage
                id="home.total_hunts"
                defaultMessage="{total} hunts"
                values={{ total: <FormattedNumber value={nights.total} /> }}
              />
            </Text>

            <Text $color="faded">
              <FormattedMessage id="home.counting" defaultMessage="and counting..." />
            </Text>

            <Link to="/upload">
              <Button $size="large" $variant="primary">
                <FlexGroup $gap={4}>
                  <Icon name="monitoring" label={formatMessage({ id: 'icon.analyze', defaultMessage: 'Analyze' })} />

                  <FormattedMessage id="home.analyze_a_hunt" defaultMessage="Analyze a hunt" />
                </FlexGroup>
              </Button>
            </Link>
          </Fragment>
        )}
      </Await>
    </Container>
  );
};

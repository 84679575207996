import { intervalToDuration } from 'date-fns';
import { styled } from 'styled-components';

import { FontSize, TextColor } from 'src/assets/theme';
import { Text } from 'src/elements/Text';
import { Mark, rate, RatingCategory } from 'src/utils/helpers/hunts/rateTime';

const Rated = styled(Text)<{ $mark: Mark }>`
  color: ${({ theme, $mark }) => theme.colors.ratings[$mark]};
`;

interface Props {
  value?: number | null;
  category?: RatingCategory;
  size?: FontSize;
  color?: TextColor;
  millis?: boolean;
}

export const Time = ({ value, category, size = 'caption', color = 'base', millis = false }: Props) => {
  if (value === undefined || value === null) {
    return (
      <Text $size={size} $color="faded">
        &mdash;
      </Text>
    );
  }

  const { hours = 0, minutes = 0, seconds = 0 } = intervalToDuration({ start: 0, end: Math.round(value * 1000) });
  const milliseconds = Math.abs(Math.round((value % 1) * 1000));
  const minus = value < 0 ? '-' : '';
  const time = millis
    ? `${minus}${seconds + minutes * 60}.${String(milliseconds).padStart(3, '0')}s`
    : `${minus}${hours === 0 ? '' : hours + ':'}${minutes}:${String(seconds).padStart(2, '0')}.${String(
        milliseconds
      ).padStart(3, '0')}`;

  if (!category) {
    return (
      <Text $size={size} $color={color}>
        {time}
      </Text>
    );
  }

  const mark = rate(value, category);

  return (
    <Rated $size={size} $mark={mark}>
      {time}
    </Rated>
  );
};

import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { styled } from 'styled-components';

import { FlexGroup } from 'src/elements/FlexGroup';
import { ExternalLink, Link } from 'src/elements/Link';
import { Text } from 'src/elements/Text';
import { Locale, localeMap } from 'src/translations/locales';
import { localeAtom } from 'src/utils/atoms/intl';
import { useSettings } from 'src/utils/hooks/useSettings';

const Container = styled.footer`
  padding: 64px 0;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.colors.secondary10},
    ${({ theme }) => theme.colors.secondary5}
  );
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;

  @media only screen and (max-width: 1032px) {
    padding: 0 16px;
  }

  @media only screen and (min-width: 1032px) {
    width: 1000px;
  }
`;

export const Footer = () => {
  const setLocale = useSetAtom(localeAtom);
  const { pathname } = useLocation();
  const { patreonUrl, apiSpecUrl, discordUrl } = useSettings();

  // Scroll to top on nav.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container>
      <Content>
        <div style={{ flex: 1 }}>
          <Text style={{ marginBottom: 12, display: 'flex' }} $color="faded" $size="small" $uppercase>
            <FormattedMessage id="layout.resources" defaultMessage="Resources" />
          </Text>

          <FlexGroup $vertical $gap={8} style={{ height: 'auto' }}>
            <ExternalLink href={discordUrl}>
              <Text $color="faded">Discord</Text>
            </ExternalLink>

            <Link to="/database?show=nights">
              <Text $color="faded">
                <FormattedMessage id="layout.database" defaultMessage="Database" />
              </Text>
            </Link>

            <Link to="/pt">
              <Text $color="faded">
                <FormattedMessage id="layout.pt" defaultMessage="PT analyzer" />
              </Text>
            </Link>

            <ExternalLink href={apiSpecUrl}>
              <Text $color="faded">
                <FormattedMessage id="layout.spec" defaultMessage="API spec" />
              </Text>
            </ExternalLink>
          </FlexGroup>
        </div>

        <div style={{ flex: 1 }}>
          <Text style={{ marginBottom: 12, display: 'flex' }} $color="faded" $size="small" $uppercase>
            <FormattedMessage id="layout.support" defaultMessage="Support us" />
          </Text>

          <FlexGroup $vertical $gap={8} style={{ height: 'auto' }}>
            <ExternalLink href={patreonUrl}>
              <Text $color="faded">Patreon</Text>
            </ExternalLink>
          </FlexGroup>
        </div>

        <div style={{ flex: 1 }}>
          <Text style={{ marginBottom: 12, display: 'flex' }} $color="faded" $size="small" $uppercase>
            <FormattedMessage id="layout.language" defaultMessage="Language" />
          </Text>

          <FlexGroup $vertical $gap={8} style={{ height: 'auto' }}>
            {Object.entries(localeMap).map(([key, { name }]) => (
              <Link key={key} to="#" onClick={() => setLocale({ locale: key as Locale })}>
                <Text $color="faded">{name}</Text>
              </Link>
            ))}
          </FlexGroup>
        </div>
      </Content>
    </Container>
  );
};

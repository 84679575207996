import { GetProfileResponse } from '@idalon/api-client';
import { useAtom } from 'jotai';

import { sessionAtom } from 'src/utils/atoms/session';

export const useSession = () => {
  const [{ isLoggedIn, user }, setSession] = useAtom(sessionAtom);

  return {
    isLoggedIn,
    user,
    logout: () => {
      setSession({ isLoggedIn: false, user: undefined });
    },
    loginAs: (user: GetProfileResponse) => {
      setSession({ isLoggedIn: true, user });
    },
  };
};

export const useMember = () => {
  const session = useSession();

  if (!session.user) {
    throw new Error();
  }

  return session.user;
};

import { FormattedMessage, useIntl } from 'react-intl';

import { Dialog } from 'src/components/ui/Dialog';
import { Button } from 'src/elements/Button';
import { FlexGroup, Spacer } from 'src/elements/FlexGroup';

export const SessionExpiredDialog = () => {
  const { formatMessage } = useIntl();

  return (
    <Dialog
      title={formatMessage({ id: 'session_expired.title', defaultMessage: 'Session expired' })}
      description={formatMessage({
        id: 'session_expired.description',
        defaultMessage: 'Your session has expired, please login again to view this page.',
      })}
    >
      {({ hide }) => (
        <FlexGroup $gap={16}>
          <Spacer />

          <Button
            $variant="primary"
            onClick={() => {
              window.location.href = '/';
              hide();
            }}
          >
            <FormattedMessage id="session_expired.button" defaultMessage="Got it" />
          </Button>
        </FlexGroup>
      )}
    </Dialog>
  );
};

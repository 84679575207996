import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Dialog } from 'src/components/ui/Dialog';
import { Button } from 'src/elements/Button';
import { FlexGroup } from 'src/elements/FlexGroup';
import { Svg } from 'src/elements/Svg';
import { getFingerprint } from 'src/utils/helpers/fingerprint/getFingerprint';

const DialogBody = styled(FlexGroup)`
  margin: -16px;
  width: calc(100% + 32px);
  padding: 32px;
`;

export const SignInDialog = () => {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();

  const redirectToProvider = async (provider: string) => {
    const url = new URL(`${process.env.REACT_APP_API_URL ?? ''}/v2/auth/${provider}`);

    url.searchParams.set('fingerprint', await getFingerprint());
    url.searchParams.set('redirectTo', pathname);

    window.location.href = url.toString();
  };

  return (
    <Dialog
      title={formatMessage({ id: 'sign_in_dialog.title', defaultMessage: 'Join idalon.com' })}
      description={formatMessage({
        id: 'sign_in_dialog.description',
        defaultMessage: 'Logging in will allow you to verify your Warframe IGN, manage your hunts and much more.',
      })}
    >
      {() => (
        <DialogBody $flex={0} $vertical $gap={8}>
          <Button onClick={() => redirectToProvider('steam')} $size="large" $variant="outline" $fullwidth>
            <FlexGroup $gap={8} $flex={0}>
              <Svg name="steam" />

              <FormattedMessage id="sign_in_modal.sign_in_via_steam" defaultMessage="Sign in via Steam" />
            </FlexGroup>
          </Button>

          <Button onClick={() => redirectToProvider('patreon')} $size="large" $variant="outline" $fullwidth>
            <FlexGroup $gap={8} $flex={0}>
              <Svg name="patreon" />

              <FormattedMessage id="sign_in_modal.sign_in_via_patreon" defaultMessage="Sign in via Patreon" />
            </FlexGroup>
          </Button>
        </DialogBody>
      )}
    </Dialog>
  );
};

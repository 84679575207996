import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { deviceWidths } from 'src/assets/theme';
import { Button } from 'src/elements/Button';
import { FlexGroup } from 'src/elements/FlexGroup';
import { Heading, Text } from 'src/elements/Text';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 400px;
  margin: 0 auto;
  height: 100%;

  @media only screen and (max-width: ${deviceWidths.tablet}px) {
    width: 100%;
  }
`;

export const SomethingWentWrong = () => {
  return (
    <Container>
      <Helmet>
        <title>idalon.com ~ error</title>
      </Helmet>

      <Heading $color="base">
        <FormattedMessage id="error.title" defaultMessage="Oh no!" />
      </Heading>

      <Text $centered $color="faded">
        <FormattedMessage
          id="error.body"
          defaultMessage="We're sorry but the app has crashed. Feel free to report this error to the dev team on Discord.{br}{br}For now though, let's just take you back."
          values={{ br: <br /> }}
        />
      </Text>

      <Link to="/">
        <Button>
          <FlexGroup $gap={4}>
            <FormattedMessage id="error.go_back" defaultMessage="Go to homepage" />
          </FlexGroup>
        </Button>
      </Link>
    </Container>
  );
};

import { SquadMember, SquadRole, UserScope } from '@idalon/api-client';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';

import { messages } from 'src/assets/messages';
import { FontSize } from 'src/assets/theme';
import { Link } from 'src/elements/Link';
import { Text } from 'src/elements/Text';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

interface Props {
  users: SquadMember[];
  squadSize: number;
  size?: FontSize;
  withLinks?: boolean;
}

const Ign = ({ member, size, withLinks }: { member: SquadMember; size: FontSize; withLinks: boolean }) => {
  if (!member.ign || !member.role || !member.uuid) {
    return (
      <Text $color="faded" $italic $size={size}>
        <FormattedMessage {...messages.anonymous} />
      </Text>
    );
  }

  const ign = (
    <Text $color={member.scope === UserScope.private ? 'faded' : 'base'} $size={size}>
      {member.ign}
    </Text>
  );

  if (withLinks) {
    return <Link to={`/users/${member.ign}`}>{ign}</Link>;
  }

  return (
    <Text $color="base" $size={size}>
      {ign}
    </Text>
  );
};

export const SquadMembers = ({ users, squadSize, withLinks = false, size = 'normal' }: Props) => {
  const host = users.find((user) => user.role === SquadRole.host);
  const clients = Array(squadSize - 1).fill({ uuid: null, ign: null, role: null });

  users.filter((user) => user.role !== SquadRole.host).forEach((user, key) => (clients[key] = user));

  if (!host) {
    return null;
  }

  return (
    <Container>
      <Text $size="small" $color="primary">
        <FormattedMessage id="host_indicator" defaultMessage="H" />
      </Text>

      <div>
        <Ign member={host} size={size} withLinks={withLinks} />

        {clients.map((client, key) => (
          <Fragment key={key}>
            <Text $color="base">,</Text> <Ign member={client} size={size} withLinks={withLinks} />
          </Fragment>
        ))}
      </div>
    </Container>
  );
};

import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { deviceWidths } from 'src/assets/theme';
import { Button } from 'src/elements/Button';
import { FlexGroup } from 'src/elements/FlexGroup';
import { Heading, Text } from 'src/elements/Text';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 400px;
  margin: 0 auto;

  @media only screen and (max-width: ${deviceWidths.tablet}px) {
    width: 100%;
  }
`;

export const NotFound = () => {
  return (
    <Container>
      <Helmet>
        <title>idalon.com ~ 404</title>
      </Helmet>

      <Heading $color="base">
        <FormattedMessage id="not_found.title" defaultMessage="Four-oh-four" />
      </Heading>

      <Text $centered $color="faded">
        <FormattedMessage
          id="not_found.body"
          defaultMessage="We're sorry but this page could not be found or you do not have the correct permissions to view it. If you believe this is an error feel free to contact the dev team on Discord.{br}{br}For now though, let's just take you back."
          values={{ br: <br /> }}
        />
      </Text>

      <Link to="/">
        <Button>
          <FlexGroup $gap={4}>
            <FormattedMessage id="not_found.go_back" defaultMessage="Go to homepage" />
          </FlexGroup>
        </Button>
      </Link>
    </Container>
  );
};

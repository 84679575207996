import { atom } from 'jotai';
import { FunctionComponent } from 'react';

import { ToastItem } from 'src/components/ui/Toast';
import { persistentAtom } from 'src/utils/helpers/atoms/persistentAtom';

export const toastsAtom = atom<{ toasts: ToastItem[] }>({ toasts: [] });

export const activeDialogAtom = atom<{ Component: FunctionComponent<any> | null; props: any }>({
  Component: null,
  props: {},
});

export const dismissedAlertsAtom = persistentAtom<{ ids: string[] }>('dismissedAlerts', { ids: [] });

export enum RatingCategory {
  lastHydrolystLimbBreakTime = 'lastHydrolystLimbBreakTime',
  limbBreakTime = 'limbBreakTime',
  firstTeralystLimbBreakTime = 'firstTeralystLimbBreakTime',
  waterLimbTime = 'waterLimbTime',
  capshotTime = 'capshotTime',
  activeTime = 'activeTime',
  realTime = 'realTime',
  rank = 'rank',
}

export enum Mark {
  f = 'f',
  g = 'g',
  spp = 'spp',
  sp = 'sp',
  s = 's',
  a = 'a',
  b = 'b',
  c = 'c',
}

const thresholds: Record<RatingCategory, { mark: Mark; threshold?: number }[]> = {
  lastHydrolystLimbBreakTime: [
    { mark: Mark.f, threshold: 402 },
    { mark: Mark.g, threshold: 410 },
    { mark: Mark.spp, threshold: 418 },
    { mark: Mark.sp, threshold: 425 },
    { mark: Mark.s, threshold: 430 },
    { mark: Mark.a, threshold: 440 },
    { mark: Mark.b, threshold: 450 },
    { mark: Mark.c, threshold: 490 },
    { mark: Mark.f },
  ],
  limbBreakTime: [
    { mark: Mark.sp, threshold: 0.1 },
    { mark: Mark.s, threshold: 0.3 },
    { mark: Mark.a, threshold: 0.7 },
    { mark: Mark.b, threshold: 1.7 },
    { mark: Mark.c, threshold: 2.7 },
    { mark: Mark.f },
  ],
  firstTeralystLimbBreakTime: [
    { mark: Mark.g, threshold: 31.0 },
    { mark: Mark.spp, threshold: 31.3 },
    { mark: Mark.sp, threshold: 32.5 },
    { mark: Mark.s, threshold: 33.5 },
    { mark: Mark.a, threshold: 35 },
    { mark: Mark.b, threshold: 40 },
    { mark: Mark.c, threshold: 45 },
    { mark: Mark.f },
  ],
  waterLimbTime: [
    { mark: Mark.g, threshold: 1.0 },
    { mark: Mark.spp, threshold: 2.0 },
    { mark: Mark.sp, threshold: 3.5 },
    { mark: Mark.s, threshold: 5.5 },
    { mark: Mark.a, threshold: 7 },
    { mark: Mark.b, threshold: 10 },
    { mark: Mark.c, threshold: 13 },
    { mark: Mark.f },
  ],
  capshotTime: [
    { mark: Mark.sp, threshold: 0.1 },
    { mark: Mark.s, threshold: 0.3 },
    { mark: Mark.a, threshold: 0.7 },
    { mark: Mark.b, threshold: 1.7 },
    { mark: Mark.c, threshold: 2.7 },
    { mark: Mark.f },
  ],
  activeTime: [
    { mark: Mark.g, threshold: 2860 },
    { mark: Mark.spp, threshold: 2900 },
    { mark: Mark.sp, threshold: 2925 },
    { mark: Mark.s, threshold: 2950 },
    { mark: Mark.a, threshold: 3000 },
    { mark: Mark.b, threshold: 3050 },
    { mark: Mark.c, threshold: 3100 },
    { mark: Mark.f },
  ],
  realTime: [
    { mark: Mark.f, threshold: 455 },
    { mark: Mark.g, threshold: 469 },
    { mark: Mark.spp, threshold: 477 },
    { mark: Mark.sp, threshold: 484 },
    { mark: Mark.s, threshold: 489 },
    { mark: Mark.a, threshold: 499 },
    { mark: Mark.b, threshold: 509 },
    { mark: Mark.c, threshold: 529 },
    { mark: Mark.f },
  ],
  rank: [
    { mark: Mark.spp, threshold: 0.001 },
    { mark: Mark.sp, threshold: 0.01 },
    { mark: Mark.s, threshold: 0.05 },
    { mark: Mark.a, threshold: 0.1 },
    { mark: Mark.b, threshold: 0.2 },
    { mark: Mark.c, threshold: 0.5 },
    { mark: Mark.f },
  ],
};

export const rate = (value: number, category: RatingCategory) => {
  const slot = thresholds[category].find((slot) => {
    return slot.threshold === undefined || slot.threshold >= value;
  });

  return slot?.mark ?? Mark.f;
};

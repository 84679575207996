import * as RadixToast from '@radix-ui/react-toast';
import { useAtom } from 'jotai';
import { styled } from 'styled-components';

import { Toast } from 'src/components/ui/Toast';
import { toastsAtom } from 'src/utils/atoms/ui';

const ViewportContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9;
`;

const Viewport = styled(RadixToast.Viewport)`
  width: 100%;
  padding: 16px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
  outline: none;

  @media only screen and (min-width: 1032px) {
    width: 1000px;
  }
`;

export const ToastProvider = () => {
  const [{ toasts }] = useAtom(toastsAtom);

  return (
    <RadixToast.Provider swipeDirection="left">
      {toasts.map(({ id, message, ...toast }) => (
        <Toast key={id} {...toast}>
          {message}
        </Toast>
      ))}

      <ViewportContainer>
        <Viewport />
      </ViewportContainer>
    </RadixToast.Provider>
  );
};

import { Provider as RadixTooltipProvider } from '@radix-ui/react-tooltip';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as JotaiProvider } from 'jotai';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { GlobalStyle } from 'src/assets/GlobalStyle';
import { ThemeProvider } from 'src/providers/ThemeProvider';

import { queryClient } from './api/queryClient';
import { IntlProvider } from './providers/IntlProvider';
import { SessionProvider } from './providers/SessionProvider';
import { router } from './router';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <QueryClientProvider client={queryClient}>
    <JotaiProvider>
      <RadixTooltipProvider delayDuration={100}>
        <ThemeProvider>
          <IntlProvider>
            <SessionProvider>
              <RouterProvider router={router} />
            </SessionProvider>
          </IntlProvider>

          <GlobalStyle />
        </ThemeProvider>
      </RadixTooltipProvider>

      <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
    </JotaiProvider>
  </QueryClientProvider>
);

// TODO: Remove later
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}

// TODO: Remove season 3 dialog eventually.
// TODO: Credits page?
// TODO: redirect tools/analyzer

// v2 TODO: hide verification section when verified?
// v2 TODO: clean indicator
// v2 TODO: read rules check on verification?
// v2 TODO: customize front page

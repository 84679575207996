import * as RadixNavMenu from '@radix-ui/react-navigation-menu';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { css, styled } from 'styled-components';

import idalonIcon from 'src/assets/icons/logo-128.png';
import { messages } from 'src/assets/messages';
import { boxShadow } from 'src/assets/mixins';
import { SignInDialog } from 'src/components/dialogs/SignInDialog';
import { Dropdown } from 'src/components/ui/Dropdown';
import { Image } from 'src/components/ui/Image';
import { Button } from 'src/elements/Button';
import { FlexGroup, Spacer } from 'src/elements/FlexGroup';
import { Icon } from 'src/elements/Icon';
import { ExternalLink } from 'src/elements/Link';
import { Text } from 'src/elements/Text';
import { useDialog } from 'src/utils/hooks/useDialog';
import { useSession } from 'src/utils/hooks/useSession';
import { useSettings } from 'src/utils/hooks/useSettings';
import { useViewport } from 'src/utils/hooks/useViewport';

import { HunterSearch } from './HunterSearch';
import { MobileNavDropdown } from './MobileNavDropdown';
import { ProfileDropdown } from './ProfileDropdown';

const List = styled(RadixNavMenu.List)`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 64px;
  padding: 4px;
  padding-right: 16px;
  gap: 8px;
  background: ${({ theme }) => theme.colors.secondary15};
  border: 1px solid ${({ theme }) => theme.colors.secondary5};
  ${boxShadow}
`;

const base = css`
  height: 42px;
  min-width: 42px;
  padding: 0 6px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  color: ${({ theme }) => theme.textColors.base};
  border-radius: 2px;

  &:focus {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.secondaryHover};
  }
`;

const Link = styled(RadixNavMenu.Link)`
  cursor: pointer;
  ${base}
`;

const Trigger = styled(RadixNavMenu.Trigger)`
  ${base}
`;

export const NavMenu = () => {
  const { show } = useDialog(SignInDialog);
  const { formatMessage } = useIntl();
  const { isLoggedIn } = useSession();
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useViewport();
  const { patreonUrl } = useSettings();

  const showAnalyzeLink = location.pathname !== '/' && location.pathname !== '/upload';

  if (isMobile) {
    return (
      <RadixNavMenu.Root>
        <List>
          <RadixNavMenu.Item>
            <Link as={RouterLink} to="/">
              <Image ratio={1} src={idalonIcon} alt={formatMessage(messages.name)} />
            </Link>
          </RadixNavMenu.Item>

          <Spacer />

          <ExternalLink $clear href={patreonUrl}>
            <Button $variant="secondary">
              <FlexGroup $gap={4}>
                <Icon name="coffee" label={formatMessage({ id: 'icon.coffee', defaultMessage: 'Buy us a coffee' })} />

                <FormattedMessage id="nav.patreon" defaultMessage="Support us" />
              </FlexGroup>
            </Button>
          </ExternalLink>

          <HunterSearch />

          <MobileNavDropdown />
        </List>
      </RadixNavMenu.Root>
    );
  }

  return (
    <RadixNavMenu.Root>
      <List>
        <RadixNavMenu.Item>
          <Link as={RouterLink} to="/">
            <Image ratio={1} src={idalonIcon} alt={formatMessage(messages.name)} />
          </Link>
        </RadixNavMenu.Item>

        <RadixNavMenu.Item>
          <Link as={RouterLink} to="/leaderboards">
            <Text $color="base">
              <FormattedMessage id="nav.leaderboards" defaultMessage="Leaderboards" />
            </Text>
          </Link>
        </RadixNavMenu.Item>

        <Dropdown
          side="bottom"
          align="center"
          sideOffset={2}
          alignOffset={0}
          trigger={
            <RadixNavMenu.Item>
              <Trigger>
                <FormattedMessage id="nav.games" defaultMessage="Games" />
              </Trigger>
            </RadixNavMenu.Item>
          }
          items={[
            {
              type: 'text',
              label: formatMessage({ id: 'nav.raplak', defaultMessage: 'Raplak' }),
              onClick: () => navigate('/games/raplak'),
            },
            {
              type: 'text',
              label: formatMessage({ id: 'nav.propa', defaultMessage: 'Propa' }),
              onClick: () => navigate('/games/propa'),
            },
            {
              type: 'text',
              label: formatMessage({ id: 'nav.teralyst', defaultMessage: 'Teralyst' }),
              onClick: () => navigate('/games/teralyst'),
            },
          ]}
        />

        <Spacer />

        <ExternalLink $clear href={patreonUrl}>
          <Button $variant="secondary">
            <FlexGroup $gap={4}>
              <Icon name="coffee" label={formatMessage({ id: 'icon.coffee', defaultMessage: 'Buy us a coffee' })} />

              <FormattedMessage id="nav.patreon" defaultMessage="Support us" />
            </FlexGroup>
          </Button>
        </ExternalLink>

        {showAnalyzeLink && (
          <RouterLink to="/upload">
            <Button $variant="primary">
              <FlexGroup $gap={4}>
                <Icon
                  $filled
                  name="monitoring"
                  label={formatMessage({ id: 'icon.analyze', defaultMessage: 'Analyze' })}
                />

                <FormattedMessage id="nav.analyze" defaultMessage="Analyze" />
              </FlexGroup>
            </Button>
          </RouterLink>
        )}

        <HunterSearch />

        {isLoggedIn ? (
          <ProfileDropdown />
        ) : (
          <Button $variant="text" onClick={() => show({})}>
            <FormattedMessage {...messages.sign_in} />
          </Button>
        )}
      </List>
    </RadixNavMenu.Root>
  );
};

import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { Fragment, ReactNode } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import { queryKeys } from 'src/api/queryKeys';
import { Locale } from 'src/translations/locales';
import { localeAtom } from 'src/utils/atoms/intl';

interface Props {
  children: ReactNode[] | ReactNode;
}

interface LocaleConfig {
  messages: Record<string, string>;
  configureTime: () => void;
}

export const IntlProvider = ({ children }: Props) => {
  const { locale } = useAtomValue(localeAtom);

  const { data: messages = {}, isLoading } = useQuery({
    queryKey: queryKeys.translations({ locale }),
    queryFn: async () => {
      const { messages, configureTime } = (await import(`src/translations/configs/${locale}.ts`)) as LocaleConfig;

      configureTime();

      return messages;
    },
    keepPreviousData: true,
    cacheTime: 0,
  });

  return (
    <ReactIntlProvider locale={locale} messages={messages} textComponent={Fragment} defaultLocale={Locale.en}>
      {isLoading || children}
    </ReactIntlProvider>
  );
};

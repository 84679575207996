import * as RadixAspectRatio from '@radix-ui/react-aspect-ratio';
import { styled } from 'styled-components';

interface Props {
  src: string;
  alt: string;
  ratio?: number;
}

const Root = styled(RadixAspectRatio.Root)`
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
  }
`;

export const Image = ({ src, alt, ratio = 16 / 9 }: Props) => {
  return (
    <Root ratio={ratio}>
      <img src={src} alt={alt} />
    </Root>
  );
};

import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  /* fallback */
  @font-face {
    font-family: 'Material Symbols Outlined';
    font-style: normal;
    font-weight: 200;
    src: url('/static/fonts/material_symbols.woff2') format('woff2');
  }

  .material-symbols-outlined {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
  }

  *, *:before, *:after {
    margin: 0;
    padding: 0;
    color: inherit;
    list-style-type: none;
    outline: none;
    background: none;
    border: none;
    text-decoration: none;

    box-sizing: border-box;
    line-height: 125%;
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizes.normal};
  }

  a {
    all: unset;
    cursor: pointer;
  }

  body {
    color: red;
    background: ${({ theme }) => theme.colors.secondary10};
    overflow-y: scroll;
    height: 100vh;
  }

  #root {
    height: 100vh;
  }

  ::-webkit-scrollbar {
    width: 8px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.secondary5};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.secondary30};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.secondaryHover};
  }
`;

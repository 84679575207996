import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'src/api/queryKeys';

import { settingsApi } from './api';

export const useGetPublicSettingsQuery = () =>
  useQuery({
    queryKey: queryKeys.publicSettings(),
    queryFn: () => settingsApi.getPublicSettings(),
    select: (data) => data.data,
    staleTime: 300000,
  });

import { useGetPublicSettingsQuery } from 'src/api/settings/query';

export const useSettings = () => {
  const { data } = useGetPublicSettingsQuery();

  return {
    currentSeason: 3,
    discordUrl: '',
    forumVerifierBotId: '',
    notificationTitle: '',
    notificationBody: '',
    notificationActive: false,
    leaderboardsLocked: false,
    patreonUrl: 'https://www.patreon.com/user?u=6645620',
    apiSpecUrl: 'https://app.swaggerhub.com/apis/kouks/idalon.com',
    ...data,
  };
};

import axios from 'axios';

interface PtRelease {
  id: number;
  version: string;
  title: string;
  description: string;
  download: string;
  publishedAt: number;
}

export const utilsApi = {
  getBountyExpiry: async () => axios.get<{ expiryDate: number }>(`${process.env.REACT_APP_UTILS_URL}/time`),
  getPtReleases: async () =>
    axios.get<{ total: number; items: PtRelease[] }>(`${process.env.REACT_APP_UTILS_URL}/pt/releases`),
};

import { useQuery } from '@tanstack/react-query';

import { queryKeys } from 'src/api/queryKeys';

import { statsApi } from './api';

export const useGetStatsQuery = ({ userUuid, enabled = true }: { userUuid?: string; enabled?: boolean }) =>
  useQuery({
    queryKey: queryKeys.stats({ userUuid }),
    queryFn: () => statsApi.getUserStats({ userUuid }),
    select: (data) => data.data,
    staleTime: 300000,
    enabled,
  });

import { useAtom } from 'jotai';
import { ReactNode } from 'react';
import { v4 } from 'uuid';

import { ToastItem, ToastVariant } from 'src/components/ui/Toast';
import { toastsAtom } from 'src/utils/atoms/ui';

export const useToast = () => {
  const [{ toasts }, setToasts] = useAtom(toastsAtom);

  const create = ({ id, message, variant }: { id?: string } & Pick<ToastItem, 'message' | 'variant'>) => {
    const toastId = id ?? v4();

    const toast = {
      id: toastId,
      message,
      variant,
    };

    setToasts({ toasts: [...toasts.filter(({ id }) => id !== toastId), toast] });

    return toastId;
  };

  return {
    create,

    dismiss: (toastId: string) => {
      setToasts({ toasts: toasts.filter(({ id }) => id !== toastId) });
    },

    dismissAll: () => {
      setToasts({ toasts: [] });
    },

    success: (message: ReactNode) => create({ message, variant: ToastVariant.success }),
    error: (message: ReactNode) => create({ message, variant: ToastVariant.error }),
    info: (message: ReactNode) => create({ message, variant: ToastVariant.info }),
  };
};

import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { css, styled } from 'styled-components';

import { slideAndFade } from 'src/assets/keyframes';
import { animationTiming, boxShadow } from 'src/assets/mixins';
import { FlexGroup, Spacer } from 'src/elements/FlexGroup';
import { Icon, IconName } from 'src/elements/Icon';

export interface TextItem {
  label: string;
  type: 'text';
  onClick: () => void;
  disabled?: boolean;
  icon?: IconName;
}

export interface LabelItem {
  label: string;
  type: 'label';
}

export interface SeparatorItem {
  // Needed for keying.
  label: string;
  type: 'separator';
}

export interface CheckboxItem {
  label: string;
  type: 'checkbox';
  checked: boolean;
  onClick: (checked: boolean) => void;
  disabled?: boolean;
}

export interface SubmenuItem {
  label: string;
  type: 'submenu';
  items: (TextItem | LabelItem | SeparatorItem | CheckboxItem)[];
  disabled?: boolean;
  icon?: IconName;
}

export type DropdownItem = TextItem | LabelItem | SeparatorItem | CheckboxItem | SubmenuItem;

interface Props {
  items: DropdownItem[];
  trigger: ReactNode;
  side: 'top' | 'bottom' | 'right' | 'left';
  align: 'start' | 'center' | 'end';
  sideOffset: number;
  alignOffset?: number;
  disabled?: boolean;
}

const container = css`
  background: ${({ theme }) => theme.colors.secondary5};
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 4px;

  animation: ${slideAndFade} 0.4s;
  will-change: transform, opacity;

  ${animationTiming}
  ${boxShadow}
`;

const item = css`
  cursor: pointer;
  color: ${({ theme }) => theme.textColors.base};
  padding: 4px 20px;
  border-radius: 2px;

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.secondaryHover};
  }

  &[aria-disabled] {
    pointer-events: none;
    opacity: 0.6;
  }
`;

const Content = styled(RadixDropdown.Content)`
  width: 210px;
  ${container}
`;

const Separator = styled(RadixDropdown.Separator)`
  background-color: ${({ theme }) => theme.colors.secondaryHover};
  height: 1px;
  margin: 4px 8px;
`;

const Label = styled(RadixDropdown.Label)`
  color: ${({ theme }) => theme.textColors.faded};
  font-size: ${({ theme }) => theme.fontSizes.small};
  padding: 4px 20px;
  text-transform: uppercase;
`;

const Item = styled(RadixDropdown.Item)`
  ${item}
`;

const CheckItem = styled(RadixDropdown.CheckboxItem)`
  position: relative;
  ${item}
`;

const ItemIndicator = styled(RadixDropdown.ItemIndicator)`
  position: absolute;
  left: 4px;
  top: 6px;
`;

const SubTrigger = styled(RadixDropdown.SubTrigger)`
  ${item}
`;

const SubContent = styled(RadixDropdown.SubContent)`
  width: 170px;
  ${container}
`;

export const Dropdown = ({ items, trigger, side, align, sideOffset, alignOffset = -4 }: Props) => {
  const { formatMessage } = useIntl();

  const renderItem = (item: TextItem | LabelItem | SeparatorItem | CheckboxItem | SubmenuItem, key: number) => {
    if (item.type === 'separator') {
      return <Separator key={JSON.stringify(item)} />;
    }

    if (item.type === 'label') {
      const { label } = item as LabelItem;

      return <Label key={JSON.stringify(item)}>{label}</Label>;
    }

    if (item.type === 'text') {
      const { label, onClick, disabled, icon } = item as TextItem;

      return (
        <Item key={JSON.stringify(item)} onClick={onClick} disabled={disabled}>
          <FlexGroup $gap={4}>
            {icon && <Icon name={icon} label={label} />}

            {label}
          </FlexGroup>
        </Item>
      );
    }

    if (item.type === 'checkbox') {
      const { label, onClick, checked, disabled } = item as CheckboxItem;

      return (
        <CheckItem key={JSON.stringify(item)} onCheckedChange={onClick} checked={checked} disabled={disabled}>
          <ItemIndicator>
            <Icon
              name="check"
              label={formatMessage({ id: 'icon.checked_indicator', defaultMessage: 'Checked' })}
              $size="small"
            />
          </ItemIndicator>

          {label}
        </CheckItem>
      );
    }

    const { label, items, disabled, icon } = item as SubmenuItem;

    return (
      <RadixDropdown.Sub key={JSON.stringify(item)}>
        <SubTrigger disabled={disabled}>
          <FlexGroup $gap={4}>
            {icon && <Icon name={icon} label={label} />}

            {label}

            <Spacer />

            <Icon
              name="chevron_right"
              label={formatMessage({ id: 'icon.submenu_indicator', defaultMessage: 'Open submenu' })}
            />
          </FlexGroup>
        </SubTrigger>

        <RadixDropdown.Portal>
          <SubContent sideOffset={6} alignOffset={-4}>
            {items.map(renderItem)}
          </SubContent>
        </RadixDropdown.Portal>
      </RadixDropdown.Sub>
    );
  };

  return (
    <RadixDropdown.Root modal={false}>
      <RadixDropdown.Trigger asChild>{trigger}</RadixDropdown.Trigger>

      <RadixDropdown.Portal>
        <Content side={side} align={align} sideOffset={sideOffset} alignOffset={alignOffset}>
          {items.map(renderItem)}
        </Content>
      </RadixDropdown.Portal>
    </RadixDropdown.Root>
  );
};

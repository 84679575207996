import { Night, NightScope } from '@idalon/api-client';
import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { css, styled } from 'styled-components';

import { messages } from 'src/assets/messages';
import { boxShadow } from 'src/assets/mixins';
import { Ago } from 'src/components/ui/Ago';
import { LabelledItem } from 'src/components/ui/LabelledItem';
import { Pill } from 'src/components/ui/Pill';
import { FlexGroup } from 'src/elements/FlexGroup';
import { Icon } from 'src/elements/Icon';
import { Text } from 'src/elements/Text';
import { parseFormat } from 'src/utils/helpers/hunts/parseFormat';
import { RatingCategory } from 'src/utils/helpers/hunts/rateTime';
import { useViewport } from 'src/utils/hooks/useViewport';

import { SquadMembers } from './SquadMembers';
import { Time } from './Time';

const Container = styled.div<{ $vertical: boolean; $accent?: 'gold' | 'silver' | 'bronze' }>`
  background: ${({ theme }) => theme.colors.secondary10};
  border: 1px solid ${({ theme }) => theme.colors.secondary20};
  border-radius: 2px;
  padding: 16px;
  display: flex;
  gap: 16px;
  transition: background 0.2s;
  position: relative;

  ${({ $accent }) => {
    if ($accent) {
      return css`
        background: color-mix(in srgb, transparent 95%, ${({ theme }) => theme.colors.medals[$accent]});
        border: 1px solid ${({ theme }) => theme.colors.medals[$accent]};
      `;
    }
  }}

  &:hover {
    background: ${({ theme }) => theme.colors.secondaryHover};
    border-color: ${({ theme }) => theme.colors.secondary20};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary};
  }

  ${({ $vertical }) => {
    if ($vertical) {
      return css`
        flex-direction: column;
      `;
    }
  }}

  ${boxShadow}
`;

const CreatedAt = styled(FlexGroup)`
  position: absolute;
  right: 16px;
  top: 16px;
  width: auto;
`;

const Addons = styled(FlexGroup)`
  position: absolute;
  right: 16px;
  bottom: 12px;
  width: auto;
`;

interface Props {
  night: Night;
  accent?: 'gold' | 'silver' | 'bronze';
  order?: number;
  showLastHydroLimb?: boolean;
  addons?: ReactNode;
}

export const NightSummary = ({ night, accent, order, showLastHydroLimb = false, addons }: Props) => {
  const { isMobile } = useViewport();
  const { formatMessage } = useIntl();

  return (
    <div style={{ position: 'relative' }}>
      <Container $vertical={isMobile} as={Link} to={`/nights/${night.uuid}`} $accent={accent}>
        {order && (
          <LabelledItem width={0} label="#">
            <Text $color={accent ?? 'faded'} $size="caption">
              {order}
            </Text>
          </LabelledItem>
        )}

        {showLastHydroLimb ? (
          <LabelledItem label={<FormattedMessage {...messages.average_last_limb_time} />}>
            <Time
              value={night.averageLastHydrolystLimbBreakTime}
              category={RatingCategory.lastHydrolystLimbBreakTime}
            />
          </LabelledItem>
        ) : (
          <LabelledItem label={<FormattedMessage {...messages.average_real_time} />}>
            <Time value={night.averageRealTime} category={RatingCategory.realTime} />
          </LabelledItem>
        )}

        <LabelledItem label={<FormattedMessage {...messages.format} />}>
          <Text $color="base" $size="caption">
            {parseFormat(night)}
          </Text>
        </LabelledItem>

        <LabelledItem width={3} label={<FormattedMessage {...messages.hunters} />}>
          <SquadMembers users={night.users} squadSize={night.squadSize} />
        </LabelledItem>

        <CreatedAt $gap={4}>
          <Icon
            name="schedule"
            label={formatMessage({ id: 'icon.created_at', defaultMessage: 'Created at' })}
            $color="faded"
          />

          <Text $color="faded" $size="small">
            <Ago date={new Date(night.createdAt)} />
          </Text>
        </CreatedAt>
      </Container>

      <Addons $gap={4}>
        {addons}

        {!addons && night.scope !== NightScope.public && (
          <Pill>
            <FormattedMessage {...messages[night.scope]} />
          </Pill>
        )}

        {night.verified && (
          <Icon
            name="verified"
            label={formatMessage({ id: 'icon.verified_night', defaultMessage: 'Verified night' })}
            $color="primary"
            $size="caption"
          />
        )}
      </Addons>
    </div>
  );
};

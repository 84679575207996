import { Fragment, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { styled } from 'styled-components';

import { Spacer } from 'src/elements/FlexGroup';
import { DialogProvider } from 'src/providers/DialogProvider';
import { ToastProvider } from 'src/providers/ToastProvider';

import { Footer } from './Footer';
import { NavMenu } from './NavMenu';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 100%;

  @media only screen and (max-width: 1032px) {
    padding: 0 16px;
  }

  @media only screen and (min-width: 1032px) {
    width: 1000px;
  }
`;

const Content = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 128px;
`;

export const MainLayout = () => {
  const { pathname } = useLocation();

  // Scroll to top on nav.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Fragment>
      <Container>
        <DialogProvider />
        <ToastProvider />

        <NavMenu />

        <Content>
          <Outlet />
        </Content>

        <Spacer />
      </Container>

      <Footer />
    </Fragment>
  );
};

import { UserRole } from '@idalon/api-client';
import { Fragment, ReactNode, useEffect } from 'react';

import { SessionExpiredDialog } from 'src/components/dialogs/SessionExpiredDialog';
import { NotFound } from 'src/pages/fallback/NotFound';
import { useDialog } from 'src/utils/hooks/useDialog';
import { useSession } from 'src/utils/hooks/useSession';

interface Props {
  children: ReactNode;
  role?: UserRole;
}

export const Authenticated = ({ children, role }: Props) => {
  const { isLoggedIn, user } = useSession();
  const { show } = useDialog(SessionExpiredDialog);

  useEffect(() => {
    if (!isLoggedIn) {
      show({});
    }
  }, [isLoggedIn, show]);

  if (!isLoggedIn || !user) {
    return null;
  }

  if (role === UserRole.mod && user.role !== UserRole.mod && user.role !== UserRole.admin) {
    return <NotFound />;
  }

  if (role === UserRole.admin && user.role !== UserRole.admin) {
    return <NotFound />;
  }

  return <Fragment>{children}</Fragment>;
};

import { Link as RouterLink } from 'react-router-dom';
import { css, styled } from 'styled-components';

export const Link = styled(RouterLink)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  transition: border 0.2s;

  &:hover {
    border-color: transparent;
  }
`;

export const ExternalLink = styled.a.attrs<{ $clear: boolean }>((props) => ({
  rel: 'noopener noreferrer',
  target: '_blank',
  ...props,
}))`
  ${({ $clear }) => {
    if (!$clear) {
      return css`
        border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
      `;
    }
  }}

  transition: border 0.2s;

  &:hover {
    border-color: transparent;
  }
`;

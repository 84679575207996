import { ReactNode } from 'react';
import { styled } from 'styled-components';

import { Color } from 'src/assets/theme';

const Container = styled.span<{ $color: Color }>`
  background: ${({ theme }) => theme.colors.secondary5};
  border: 1px solid ${({ theme, $color }) => theme.colors[$color]};
  color: ${({ theme }) => theme.textColors.faded};
  font-size: ${({ theme }) => theme.fontSizes.small};
  padding: 1px 12px;
  border-radius: 100px;
  display: flex;
  gap: 4px;
  align-items: center;
`;

interface Props {
  children: ReactNode | ReactNode[];
  color?: Color;
}

export const Pill = ({ children, color = 'secondary20' }: Props) => {
  return <Container $color={color}>{children}</Container>;
};
